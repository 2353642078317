<template>
  <div>
    <el-form label-width="100px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="分组名称">
            <el-input placeholder="请输入"
                      v-model="keyword" />
          </el-form-item>
        </el-col>

        <el-col :span="18">
          <el-button type="primary"
                     @click="search">
            查询
          </el-button>
          <el-button type="primary"
                     @click="toAdd">
            新增
          </el-button>

        </el-col>

      </el-row>
    </el-form>
    <Form ref="FormRef" />
    <CheckStudent ref="CheckStudentRef" />
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import CheckStudent from './components/checkStudent.vue'
export default {
  data () {
    return {
      tableData: [],
      pageName: 'paper_group',
      tableColumns: [
        { prop: 'organize_id', align: 'center', label: '编号', },
        { prop: 'name', align: 'center', label: '分组名称', },
        { prop: 'school_name', align: 'center', label: '所属学校', },
        { prop: 'edu_user_name', align: 'center', label: '创建人', },
        { prop: 'create_time', align: 'center', label: '创建时间', },
        { prop: 'status', align: 'center', label: '是否启用', isStatus: true },
        {
          align: 'center', label: '操作', type: 'img', btns: [
            { content: '添加学生', type: 'add', event: 'toInfo' },
            { content: '修改', type: 'edit', event: 'editRow' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ],
      totalElements: 0,
      keyword: ''
    }
  },
  components: {
    Form,
    CheckStudent
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value, row) {
      return value ? 'pass' : 'notpass'
    },
    getValue (prop, value, row) {
      if (prop == 'status') {
        return value ? '是' : '否'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      this.$http({
        url: '/api/v1/organize/lst',
        method: 'get',
        params: {
          page, limit,
          keyword: this.keyword,
          show_status: 0
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    toAdd () {
      let form = {
        name: '',
        school_id: '',
        status: 1
      }
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true

    },
    editRow (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.form = form
      this.$refs.FormRef.dialogVisible = true
    },
    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/organize/del',
          method: 'post',
          data: {
            organize_id: row.organize_id
          }
        }).then(() => {
          this.$notify({
            title: '提示',
            message: '成功!',
            type: 'success'
          });
          this.search()
        })
      })
    },
    toInfo (row) {
      let form = {
        special_class_subject_id: '',
        special_class_id: '',
        organize_id: row.organize_id,
        keyword: ''
      }
      this.$refs.CheckStudentRef.form = form
      this.$refs.CheckStudentRef.getAlreadyHaveStudent(row.organize_id)
      this.$refs.CheckStudentRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>