<template>
  <div>
    <el-dialog title="添加学生"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="60px">
        <el-row :gutter="10">

          <el-col :span="6">
            <el-form-item label="关键词">
              <el-input clearable
                        style="width:100%"
                        placeholder="账号/姓名"
                        v-model="form.keyword"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="教学班">
              <el-select v-model="form.special_class_id"
                         style="width:100%"
                         clearable
                         @change="getSubjectList"
                         placeholder="请选择">
                <el-option v-for="item in classList"
                           :key="item.class_id"
                           :label="item.class_name"
                           :value="item.class_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="6">
            <el-form-item label="科目">
              <el-select v-model="form.special_class_subject_id"
                         style="width:100%"
                         clearable
                         placeholder="选择">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="4">
            <el-button type="primary"
                       @click="search">查询</el-button>
          </el-col>
        </el-row>
      </el-form>
      <pagination ref="pagination"
                  :get-data="initData"
                  :total="totalElements" />
      <el-table border
                :stripe="true"
                ref="multipleTable"
                highlight-current-row
                :row-key="getRowKeys"
                :max-height="$store.state.tableHeight / 2"
                :data="tableData"
                @selection-change="handleSelectionChange"
                style="width: 100%">
        <el-table-column type="selection"
                         :reserve-selection="true"
                         width="55" />
        <el-table-column prop="truename"
                         align="center"
                         label="真实姓名" />
        <el-table-column prop="verifiedMobile"
                         align="center"
                         label="手机号" />
      </el-table>
      <p style="font-size:18px;font-weight:bold;line-height:50px"><strong>已选择学生：</strong></p>
      <el-table border
                :stripe="true"
                highlight-current-row
                :max-height="$store.state.tableHeight / 2"
                :data="showList"
                style="width: 100%">
        <el-table-column prop="truename"
                         align="center"
                         label="真实姓名" />
        <el-table-column prop="verifiedMobile"
                         align="center"
                         label="手机号" />
        <el-table-column align="center"
                         label="操作">
          <template slot-scope="scope">
            <div class="text_btn_danger"
                 @click="delStu(scope.row,scope.$index)">删除</div>
          </template>
        </el-table-column>
      </el-table>
      <pagination ref="pagination1"
                  :nowPage='checkStudentPage'
                  :nowSize='checkStudentSize'
                  :get-data="onShowSizeChange"
                  :total="checkStudentCount" />
      <div class="btn">
        <el-button type="primary"
                   @click="save">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: {
        grade_id: '',
        class_id: '',
      },
      width: '800px',
      page: 1,
      size: 10,
      totalElements: 0,
      dialogVisible: false,
      classList: [],
      tableData: [],
      checkStudentArr: [],
      subjectList: [],
      // otherStudent: [],//选择的学生
      checkStudentCount: 0,//已选择学生分页
      showList: [],//已选择学生当前页
      originCount: 0,
      checkStudentPage: 1,
      checkStudentSize: 10,
      originList: [],
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getRowKeys (row) {
      return row.id
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    // search1 () {
    //   this.$refs.pagination1.toFirstPage()
    // },

    async delStu (row, index) {
      await this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      if (row.of_organize_id) {
        await this.$http({
          url: '/api/v1/organize/reject',
          method: 'post',
          data: {
            of_organize_id: row.of_organize_id
          }
        })
        // this.originList.splice(index, 1)
        // this.checkStudentArr.splice(index, 1)
        let thisIndex = (this.checkStudentPage - 1) * this.checkStudentSize + index
        this.originList.splice(thisIndex, 1)
        this.checkStudentArr.splice(thisIndex, 1)
        this.originCount--
        this.checkStudentCount--
        this.onShowSizeChange(this.checkStudentPage, this.checkStudentSize)
      } else {
        this.$refs.multipleTable.toggleRowSelection(this.checkStudentArr[index], false)
      }
    },
    getSubjectList () {
      this.form.special_class_subject_id = ''
      this.subjectList = []
      if (this.form.special_class_id == '') return
      this.$http({
        url: '/api/v1/public/class_grade_subject',
        method: 'get',
        params: {
          class_id: this.form.special_class_id,

        }
      }).then(res => {
        this.subjectList = res.data
      })
    },
    handleClose () {
      this.checkStudentArr = []
      this.tableData = []
      this.showList = []
      this.dialogVisible = false
      this.checkStudentPage = 1
      this.checkStudentSize = 10
    },
    handleSelectionChange (value) {
      this.checkStudentArr = [...this.originList, ...value]
      this.checkStudentCount = this.originCount + value.length
      this.onShowSizeChange(this.checkStudentPage, this.checkStudentSize)
    },
    getList () {
      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get',
        params: {
          all_class: 1
        }
      }).then(res => {
        this.classList = res.data
      })
    },
    getTableDtaList (form) {
      this.$http({
        url: '/api/v1/special/get_organize_user',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = Object.values(res.data.list)
        this.totalElements = res.data.count
        let list = []
        this.tableData.forEach(item => {
          this.checkStudentArr.forEach(row => {
            if (item.id == row.id) {
              list.push(item)
            }
          })
        })
      })
    },
    initData (page, size) {
      this.tableData = []
      this.totalElements = 0
      let form = JSON.parse(JSON.stringify(this.form))
      form.size = size
      form.page = page
      if (this.form.keyword !== '') {
        // console.log(456)
        this.getTableDtaList(form)
        return
      }
      // else if (!this.form.special_class_id || !this.form.special_class_subject_id) {
      //   // this.$message.warning("请选择教学班级和科目")
      //   return
      // }
      this.getTableDtaList(form)
      return
    },
    save () {
      let user_ids = this.checkStudentArr.filter(item => {
        if (!item.of_organize_id) {
          return item.id
        }
      })
      if (user_ids.length) {
        this.$http({
          url: '/api/v1/organize/insert',
          method: 'post',
          data: {
            organize_id: this.form.organize_id,
            user_ids: user_ids.map(item => item.id)
          }
        }).then(res => {
          this.$message.success('成功')
          this.handleClose()
        })
      } else {
        this.handleClose()
      }
    },

    onShowSizeChange (current, pageSize) {
      this.checkStudentSize = pageSize
      this.checkStudentPage = current
      let start = pageSize * (current - 1)
      let end = pageSize * current
      this.showList = this.checkStudentArr.slice(start, end)
    },
    getAlreadyHaveStudent (id) {
      this.$http({
        url: '/api/v1/organize/user_list',
        method: 'get',
        params: {
          organize_id: id,
          // page: this.checkStudentPage,
          // limit: this.checkStudentSize
        }
      }).then(res => {
        this.checkStudentArr = Object.values(res.data.list)
        this.checkStudentCount = res.data.count
        this.originCount = res.data.count
        this.originList = Object.values(JSON.parse(JSON.stringify(res.data.list)))
        this.onShowSizeChange(this.checkStudentPage, this.checkStudentSize)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.btn {
  text-align: center;
  margin-top: 40px;
}
</style>
